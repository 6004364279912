
.admin_content_area{
  * {
    font-family: inherit !important;
  }
}

.modal_root{
width: 100%;
--pro-modal-width: 995px;
// --pro-modal-margin: 0;
--pro-modal-border-width: 0;
}