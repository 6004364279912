
.dashboardTable {
  cursor: pointer;
  transition: all 0.3s;
}

.dashboardTable:hover {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
}

.no_padding {
  padding-top: 0 !important;
}


.reactSelectContainer{
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.order_id_danger{
  background-color: var(--pro-badge-cancelled-text);
  color: #ffffff;
  height: 18px;
  width: 18px;
  border-radius: 50%;
}


.modal_root{
  --pro-modal-width : 1200px;
}