.root{
    position: relative;
    background-color: var(--pro-secondary);
    border-radius: 4px;
    &+.root{
        margin-top: 24px;
    }
    .btn_close{
        display: block;
        margin-left: auto;
        width: 5px;
        height: 5px;
    }
}

.box_root{
    border-right: 1px solid var(--pro-outline-border);
}
.footer_btn{
    border-top: 1px solid var(--pro-outline-border);
}
.add_form_wrap{
    max-height: 60vh;
    overflow-y: auto;
}

.address_wrap{
    border-radius: 4px;
    border: 1px solid var(--pro-outline-border);
    padding: 12px;
    
    .radio_wrap{
        h6{
            flex: 1;
        }
    }
}
.right_wrapper{
    max-height: calc(60vh + 88px);
    overflow-y: auto;
}