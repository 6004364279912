.shimmer_container{
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    pointer-events: none;
    .left_td{
        height: 8px;
        width: 35%;
    }
    .right_td{
        height: 8px;
        width: 45%;
    }
    .table_item{
        ~ .table_item{
            padding-top: 1.5rem;
            margin-top: 1.1rem;
            border-top: 1px dashed var(--pro-outline-border);
        }
    }
    .tr_wrap{
        display: flex;
        margin: 5px 0;
        .left_td_wrap{
            width: 30%;
        }
        .right_td_wrap{
            width: 70%;
        }
    }
}