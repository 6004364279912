.admin_content_area{
  ol, ul{
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    li{
      list-style: inherit;
      &:not(:last-of-type){
        margin-bottom: 0.5rem;
      }
    }
  }
}

.modal_root{
  width: 100%;
  --pro-modal-width: 995px;
  // --pro-modal-margin: 0;
  --pro-modal-border-width: 0;
}