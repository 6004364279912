.frameWrap {
    --frame-max-width: 0;
    --frame-image-gap: 0;
    --frame-color: #000;
    --frame-border-size: 0;
    --frame-max-height: null;
    // --frame-border-image: url("");
    --frame-edge-width: calc(var(--frame-max-width) * 4 / 100);
    width: var(--frame-max-width);
    margin-right: var(--frame-edge-width);
    margin-bottom: var(--frame-edge-width);
    max-width: calc(100% - var(--frame-edge-width));
    .frameBorder {
    background-color: var(--frame-color);
    background-color: white;
    &:after,
    &::before {
    content: "";
    position: absolute;
    background-color: var(--frame-color);
    }
    &::before {
    top: 0;
    height: 100%;
    width: var(--frame-edge-width);
    right: calc(var(--frame-edge-width) * -1);
    transform: skewY(45deg)
    translateY(calc(var(--frame-max-width) * 2 / 100));
    }
    &:after {
    left: 0px;
    width: 100%;
    height: var(--frame-edge-width);
    bottom: calc(var(--frame-edge-width) * -1);
    transform: skewX(45deg)
    translateX(calc(var(--frame-max-width) * 2 / 100));
    }
    }
    .frameInner {
    height: 100%;
    // background-color: #fff;
    padding: var(--frame-image-gap);
    padding: calc( (var(--frame-image-gap) * var(--swiperRatio)) / 100);
    border: var(--frame-border-size) solid transparent;
    border: calc( (var(--frame-border-size) * var(--swiperRatio)) / 100) solid transparent;
    border-image: var(--frame-border-image) 12 stretch;
    box-shadow: inset 0 4px 8px 0px rgba(0, 0, 0, 0.11);
    &.loading {
    &::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: var(--frame-border-size);
    left: var(--frame-border-size);
    top: calc( (var(--frame-border-size) * var(--swiperRatio)) / 100);
    left: calc( (var(--frame-border-size) * var(--swiperRatio)) / 100);
    background-color: rgba(0, 0, 0, 0.5);
    width: calc(100% - 2 * calc( (var(--frame-border-size) * var(--swiperRatio)) / 100));
    height: calc(100% - 2 * calc( (var(--frame-border-size) * var(--swiperRatio)) / 100));
    }
    }
    }
    .img_wrap{
        position: relative;
        overflow: hidden;
        height: 100%;
        div, figure{
            height: 100%;
        }
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            left: 0;
            top: 0;
        }
      }
   }