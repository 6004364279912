@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
.root {
  width: 100%;
  position: relative;
  background-color: var(--pro-light);
  border-radius: $primary-border-radius;
  display: flex;
  flex-direction: column;
  .root_image {
    width: 100%;
    border-radius: $primary-border-radius;
    overflow: hidden;
  }
}

.root_image_inner {
  width: 100%;
  position: relative;
  border-radius: $primary-border-radius;
  overflow: hidden;
  border: 1px solid var(--pro-light);
}

.checked {
  border: 2px solid var(--pro-primary);
  &::after {
    content: "";
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--pro-primary);
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 9;
    border-radius: $border-radius-circle;
    border: 1px solid var(--pro-light);
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.8 6.58L0 3.78L0.98 2.8L2.8 4.62L7.42 0L8.4 0.98L2.8 6.58Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.rejected {
  border: 2px solid var(--pro-danger-light);
}

.active {
  border: 2px solid var(--pro-primary);
}

.root_image_lists {
  width: 100%;
  border-bottom: 1px solid var(--pro-outline-border);
  :global {
    .row {
      margin: 0px -4px;
      > * {
        padding: 4px;
      }
    }
  }
}

.buttons {
  gap: 10px;
}

.action_icon {
  width: 34px;
  display: flex;
  flex-direction: column;
  position: relative;
  position: absolute;
  right: 17px;
  top: 21px;
  z-index: 9;
  .icon {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-circle;
    overflow: hidden;
    background-color: var(--pro-light);
    transition: 0.15s transform ease-in-out;
    box-shadow: -1px 3px 5px #0006;
    svg {
      path {
        transition: 0.3s all ease;
      }
    }
    &:hover {
      transform: scale(0.95);
    }
    span {
      font-size: 22px;
    }
  }
}
.root_figure {
  width: 100%;
  position: relative;
}

.image {
  width: 100%;
  position: relative;
}
.image img {
  display: block;
}

.thumb {
  width: 100%;
  border-radius: 5px;
  padding-bottom: 100%;
  background-size: 800px 600px;
}
