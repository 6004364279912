.list_root{
  display: flex;
  flex-direction: column;
  .list_item{
    display: flex;
    & + .list_item{
      margin-top: 12px;
    } 
    .count{
      opacity: 0.7;
      font-weight: 600;
      display: inline-block;
      margin-right: 5px;
    }
  }
}