.filter_btn{
    height: 100%;
    align-items: center;
}

.reactSelectContainer{
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
  }
  .conform_pill{
    border-radius: 20px;
    padding: 4px 10px;
    color: var(--pro-light);
    font-size: 12px;
    display: inline-block;
    text-align: center;
    &.purcahsePill{
      min-width: 100px;
    }
    &.green{
        background-color: var(--pro-primary);
    }
    &.red{
        background-color: var(--pro-danger);
    }    
    &.blue{
      background-color: rgba(var(--pro-link-color-rgb), 1);
    }
  }
  
  .text_green{
    color: #19af00;
  }
  .text_red{
    color: #ff001a;
  }