@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
.root_image_inner {
  width: 100%;
  position: relative;
  border-radius: $primary-border-radius;
  overflow: hidden;
  border: 1px solid var(--pro-light);
}

.pro_editor_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  .pro_editor_main {
    padding: 24px;
    margin: 16px;
    border: 1px solid var(--pro-outline-border);
    :global {
      .public-DraftStyleDefault-block {
        margin: 0;
      }
      .DraftEditor-root {
        min-height: 160px;
        max-height: 160px;
        word-break: break-word;
        .public-DraftStyleDefault-ul,
        .public-DraftStyleDefault-ol {
          margin: 0;
          & + * {
            margin-top: 12px;
          }
        }
      }
    }
  }
  .pro_editor_toolbar {
    width: 100%;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    padding: 8px;
    background-color: var(--pro-editor-toolbar-bg);
    border-bottom: 1px solid var(--pro-outline-border);
    row-gap: 10px;
    > div{
      margin-bottom: 0;
    }
    :global {
      .rdw-inline-wrapper,
      .rdw-list-wrapper {
        margin-bottom: 0;
      }
      .rdw-option-wrapper {
        border: 0;
        background: transparent;
        min-width: 20px;
        width: 20px;
        position: relative;
        box-shadow: none !important;
        margin: 0 10px;
        --scale: 0.7;
        z-index: 2;
        &::before {
          position: absolute;
          border-radius: 0;
          background-color: var(--pro-secondary-active);
          border-radius: 50%;
          content: "";
          height: 25px;
          width: 25px;
          left: 50%;
          top: 50%;
          transition: transform 0.2s ease-in, opacity 0.2s ease-in;
          transform: scale(var(--scale)) translate(-50%, -50%);
          opacity: 0;
          will-change: opacity, transform;
          z-index: -1;
        }
        &:hover::before,
        &.rdw-option-active::before {
          opacity: 1;
          --scale: 1;
        }
      }
    }
    :global{
      .rdw-dropdown-wrapper {
        position: relative;
        background-color: var(--pro-light);
        height: auto;
        border: 0;
        border-right: 1px solid var(--pro-outline-border);
        &:hover {
          background-color: var(--pro-light);
          box-shadow: none;
        }
        > a {
          color: var(--pro-dark);
          width: 100%;
          :global(.rdw-dropdown-carettoopen) {
            border-top-color: var(--pro-dark);
            border-top: 5px solid black;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
          }
          :global(.rdw-dropdown-carettoclose) {
            border-bottom-color: var(--pro-dark);
            border-bottom: 5px solid black;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
          }
        }
        .rdw-dropdown-optionwrapper {
          position: absolute;
          top: 100%;
          margin: 5px 0;
          width: 100%;
          min-width: 150px;
          max-height: 150px;
          background-color: var(--pro-light);
          border-color: var(--pro-outline-border);
          &:hover {
            box-shadow: none;
          }
          li {
            min-height: auto;
            padding: 5px;
            justify-content: flex-start;
            font-size: 14px;
          }
          :global {
            .rdw-dropdownoption-active {
              background-color: var(--pro-primary);
              color: #ffffff; //no variables used bcause same color
              img{
                filter: invert(1);
              }
            }
            .rdw-dropdownoption-highlighted:not(.rdw-dropdownoption-active) {
              background-color: var(--pro-input-option-hover-bg);
            }
          }
        }
      }
      .rdw-fontsize-dropdown{
        min-width: 60px;
      }
      .rdw-link-modal{
        background-color: var(--pro-light);
        box-shadow: none;
        top: 100%;
        margin: 10px 0;
        left: 0;
        border: 1px solid var(--pro-outline-border);
        width: 250px;
        height: 230px;
        overflow-y: auto;      
      }
    }
    
  }
}
[data-theme*="dark"] {
  .pro_editor_toolbar {
    :global(.rdw-option-wrapper img) {
      filter: invert(1);
    }
  }
}
.editor_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid var(--pro-outline-border);
  .error_text {
    color: var(--pro-danger);
    font-size: 12px;
    line-height: 1;
    margin-top: 10px;
  }
  :global(.editor){
    height: 100%;
  }
}

.footer_btn_wrap{
  border-top: 1px solid var(--pro-outline-border);
}

.right_form{
  position: sticky;
    top: 67px;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
}