.main_wrapper {
  width: 100%;
  position: relative;
  padding-right: calc(var(--pro-gutter-x) * 0.5);
  padding-left: 0;
}

.avatar_container {
  position: relative;
  z-index: 3;

  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
}


.left_sidebar {
  width: 100%;
  max-width: 24.063rem;
  height: calc(100vh - 57px);
  position: sticky;
  top: 57px;
  overflow: hidden;
  border-right: 1px solid var(--pro-outline-border);

  &_sticky {
    position: relative;

    .order_process_wrap {
      padding: 24px 0;
    }

    .order_process {
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.active {
        background-color: var(--pro-order-process-bg);

        .green {
          pointer-events: none;
          color: #0F8D1B;
        }

        .red {
          color: rgb(226, 11, 11);
          pointer-events: none;
        }
        .gray_tick {
          --pro-button-color: var(--pro-input-active)
        }
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid #D8D8D8;
      }

      &:not(.active) {
        .tick_btn {
          --pro-button-color: var(--pro-input-active)
        }
      }
    }

    .order_details {
      .badge {
        --wac-badge-bg: #0F8D1B;
        ;
        --wac-badge-border-color: #0F8D1B;
        --wac-badge-color: var(--pro-light);
        text-transform: capitalize;
        border-radius: 0;
        padding: 4px 8px;
        line-height: 1.16;
      }
    }

    .order_items_wrap {
      padding: 24px 0;
      border-top: 1px solid var(--pro-outline-border);
      margin-top: 12px;
    }

    .order_item {
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 20px;
      cursor: pointer;

      .order_item_image {
        position: relative;
        width: 100%;
        max-width: 37.5px;
        box-shadow: 1px 1px 1px 0px #0000007d;
      }

      .item_description {
        line-height: 14px;
        max-width: 150px;
      }

      &:not(.active) {
        &:first-of-type {
          border-top: 1px solid transparent;
        }

        &+.order_item:not(.active) {
          border-top: 1px solid var(--pro-outline-border);
        }
      }

      &.active {
        background-color: #8E0E6D0D;
        border-block: 1px solid var(--pro-primary);

        &+.order_item.active {
          border-top: 0;
        }
      }
    }
  }

  .profile_layout_action {
    ul {
      padding: 0;

      li {
        display: flex;
        justify-content: center !important;
      }
    }
  }

  .profile_name_wrap :global(p) {
    margin-top: 5px;
  }

  .side_total_wrap {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    padding: 24px;
    background-color: var(--pro-light);
    box-shadow: 0px -3px 13px 0px rgba(0, 0, 0, 0.05);
  }
}

:global {
  .pt-custom {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}

.main_container {
  width: 100%;
  max-width: calc(100% - 385px);
  transition: 0.3s max-width ease-in-out;
  flex: 1;

  &_inner {
    width: 100%;
  }

  :global {
    .nav {
      padding-inline: 1.25rem; //padding chnages to margin
      padding-top: 20px;
      overflow: auto;
      flex-wrap: nowrap;
      overflow-y: hidden;
      position: sticky;
      top: 60px;
      background-color: var(--pro-light);
      z-index: 9;

      // border-bottom: 0; //removed the border of nav
      .nav-link {
        white-space: pre;

        .pro-btn-link {
          --pro-button-hover-bg: var(--pro-primary-hover-bg);
          transition: none;

          &.active {
            color: var(--pro-primary);
            border-top-left-radius: 6px;
            border-top-right-radius: 7px;
            border: none;
            box-shadow: 0 0 0 1px var(--pro-outline-border);

            &::after {
              background-color: var(--pro-primary);
            }
          }

          &:not(.active):active {
            background-color: var(--pro-button-hover-bg);
          }
        }
      }
    }
  }
}

.profile {
  width: 100%;

  >div {
    border-radius: 5px;
    overflow: hidden;
  }
}