
@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
.overview_main{
    // padding: 0 calc(1.5rem * 0.5);
    background-color: var(--pro-light);
    .overview_wrap_list{
        background-color: var(--pro-light);
        overflow-x: hidden; //astrology table overlapping
        .multi_column_table{
            position: relative;
            &::after{
                position: absolute;
                content: '';
                right: 0;
                height: calc(100% - 37px);
                width: 1px;
                bottom: 4px;
                background-color: var(--pro-outline-border);
            }
            h6 + button:global(.pro-btn){
                right: 30px;
                top: 0;
            }
        }
        &_item{
            table {
                tr{
                    td{
                        // &:first-child:nth-last-child(2), 
                        // &:first-child:nth-last-child(2) ~ td{
                        //     width: 50%;
                        // }
                        p{
                            word-break: break-word;
                            max-width: 75rem;
                            padding-right: 3.75rem;
                        }
                    }
                }
            }
            
        }
    }
    .spinner_wrap{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 999;
        // pointer-events: none;
        :global(.pro-spinner.lg){
            --wac-spinner-size: 50px;
            --wac-spiner-width: 4px;
        }
    }
}
.horoscope_table_container{
    position: relative;
    .horoscope_table_wrap{
        padding-top: 2.813rem;
    }
    .editIcon{
        position: absolute;
        right: 10px;
        top: 0;
    }
}


.shimmer_wrapper{
    width: 100%;
    position: relative;
    background-color: var(--pro-light);
}


.table_wrap{
    border-bottom: 1px dashed var(--pro-outline-border);
    padding-bottom: size(25);
    table{
        td{
          font-size: 13px;
          font-weight: 500;
          &:first-of-type{
            width: 25%;
          }
        }
      }
}