.root{
    width: 100%;
    position: relative;
    .item{
        ~ .item{
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px dashed var(--pro-border-color);
        }
    }
}
