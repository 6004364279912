.root {
  width: 100%;
  position: relative;
  height: 100%;
  .inner {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid var(--pro-outline-border);
    border-radius: 6px;
    overflow: hidden;
    border-bottom: 3px solid #cfd3dc;
    transition: 0.3s all ease-in-out;
    height: 100%;
    padding: 1rem 1.125rem;
    .icon {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--pro-outline-border);
      transition: all .3s ease-in;
      span {
        font-size: 20px;
        color: var(--pro-primary);
      }
      & + h6{
        flex: 1;
        margin-top: 4px;
      }
    }
  }
  &:hover {
    .inner {
      border-bottom: 3px solid var(--pro-primary);
    }
  }
}
:global(.active) {
  .inner {
    border-bottom: 3px solid var(--pro-primary);
    border-color: var(--pro-primary);
    .icon{
      background-color: var(--pro-primary);
      border-color: var(--pro-primary);
      span{
        color: var(--pro-light);
      }
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
  h3 + .growth_percentage{
    margin-left: 10px;
  }
  .total_value{
    font-size: 22px;
  }
  .last_month{
    font-size: 12px;
    // margin-left: 25px;
    
  }
}


.growth_percentage{
  position: relative;
  color: var(--color);
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  border-radius: 16px;
  &.increment{
    --bottom-size: 6px;
    --top-size: 0;
    --color: var(--pro-success);
    --bg-color: #03AB651A;
  }
  &.decrement{
    --bottom-size: 0;
    --top-size: 6px;
    --color: var(--pro-danger);
    --bg-color: #a11d2b1a;
    .arrow{
      transform: rotate(180deg);
    }
  }
}
