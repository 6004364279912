.image{
    width: 53px;
    height: 53px;
}
.text_1{width: 50%;}
.text_2{width: 10%;}
.root{
    & +.root{
        padding-top: 1rem;
        margin-top: 1rem;
        border-top: 1px solid var(--pro-border-color);
    }
}