.pro_editor_wrapper{
    display: flex;
    flex-direction: column-reverse;
    margin-top: 1.5rem;
    gap: 1.5rem;
    .pro_editor_main{
        padding: 10px;
        border: 1px solid var(--pro-outline-border);
        border-radius: 4px;
        :global{
          .public-DraftStyleDefault-block{
              margin: 0;
            }
            .DraftEditor-root{
              min-height: 160px;
              max-height: 160px;
              word-break: break-word;
              .public-DraftStyleDefault-ul, .public-DraftStyleDefault-ol{
                margin: 0;
                & + *{
                    margin-top: 12px;
                }
              }
            }
        }
    }
    .pro_editor_toolbar{
        width: fit-content;
        border: 0;
        border-radius: 0;
        margin-bottom: 0;
        padding: 2px 16px;
        background-color: var(--pro-editor-toolbar-bg);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
        :global{
          .rdw-inline-wrapper, .rdw-list-wrapper{
              margin-bottom: 0;
            }
            .rdw-option-wrapper{
              border: 0;
              background: transparent;
              min-width: 20px;
              width: 20px;
              position: relative;
              box-shadow: none !important;
              --scale: 0.7;
              z-index: 2;
              &::before{
                position: absolute;
                border-radius: 0;
                background-color: var(--pro-secondary-active);
                border-radius: 50%;
                content: "";
                height: 25px;
                width: 25px;
                left: 50%;
                top: 50%;
                transition: transform 0.2s ease-in, opacity 0.2s ease-in;
                transform: scale(var(--scale)) translate(-50%, -50%);
                opacity: 0;
                will-change: opacity, transform;
                z-index: -1;
              }
              &:hover::before, &.rdw-option-active::before{
                opacity: 1;
                --scale:1;
              }
            }
        }
        .dropdown_wrapper{
            position: relative;
            background-color: var(--pro-light);
            &:hover{
                background-color: var(--pro-light);
                box-shadow: none;
            }
            >a{
                color: var(--pro-dark);
                font-weight: 500;
            }
            .dropdown_menu{
                position: absolute;
                bottom: 100%;
                width: 100%;
                max-height: 150px;
                &:hover{
                    box-shadow: none;
                }
                li{
                    min-height: auto;
                    padding: 5px;
                    font-size: 14px;
                }
                :global {
                    .rdw-dropdownoption-active{
                        background-color: var(--pro-primary);
                        color: var(--pro-light);
                    }
                    .rdw-dropdownoption-highlighted:not(.rdw-dropdownoption-active){
                        background-color: var(--pro-input-option-hover-bg);
                    }
                }
            }
        }
    }
}