
@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
.overview_main{
    padding: calc(3rem * 0.5);
    background-color: var(--pro-light);
    .overview_wrap_list{
        background-color: var(--pro-light);
        overflow-x: hidden; //astrology table overlapping
        .multi_column_table{
            position: relative;
            &::after{
                position: absolute;
                content: '';
                right: 0;
                height: calc(100% - 37px);
                width: 1px;
                bottom: 4px;
                background-color: var(--pro-outline-border);
            }
            h6 + button:global(.pro-btn){
                right: 30px;
                top: 0;
            }
        }
        &_item{
            table {
                tr{
                    td{
                        &:first-child:nth-last-child(2), 
                        &:first-child:nth-last-child(2) ~ td{
                            width: 50%;
                        }
                        p{
                            word-break: break-word;
                            max-width: 75rem;
                            padding-right: 3.75rem;
                        }
                    }
                }
            }
            
        }
        .overview_wrap_list_item{
          max-width: 256px;
        }
    }
    .spinner_wrap{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 999;
        // pointer-events: none;
        :global(.pro-spinner.lg){
            --wac-spinner-size: 50px;
            --wac-spiner-width: 4px;
        }
    }
}
.horoscope_table_container{
    position: relative;
    .horoscope_table_wrap{
        padding-top: 2.813rem;
    }
    .editIcon{
        position: absolute;
        right: 10px;
        top: 0;
    }
}


.shimmer_wrapper{
    width: 100%;
    position: relative;
    background-color: var(--pro-light);
}


.main_wrapper {
    width: 100%;
    position: relative;
    padding-right: calc(var(--pro-gutter-x) * 0.5);
    padding-left: calc(var(--pro-gutter-x) * 0.5);
  }
  
  .avatar_container {
    position: relative;
    z-index: 3;
    .img_wrap {
      max-width: 18px;
      min-width: 18px;
      flex: 1;
    }
  }
  
  
  .left_sidebar {
    width: 100%;
    max-width: 24.063rem;
    height: calc(100vh - 96px);
    position: sticky;
    top: 96px;
    overflow: hidden;
    border-right: 1px solid var(--pro-outline-border);
    &_sticky {
      position: relative;
      &_scrollWrap {
        padding-bottom: 20px;
      }
    }
    
    .profile_name_wrap :global(p){
      margin-top: 5px;
    }
  }
  :global {
    .pt-custom {
      position: sticky;
      top: 0;
      z-index: 999;
    }
  }
  
  .main_container {
    width: 100%;
    max-width: calc(100% - 385px);
    transition: 0.3s max-width ease-in-out;
    flex: 1;
    &_inner {
      width: 100%;
    }
    :global {
      .nav {
        padding-inline: 1.25rem; //padding chnages to margin
        padding-top: 20px;
        overflow: auto;
        flex-wrap: nowrap;
        overflow-y: hidden;
        position: sticky;
        top: 60px;
        background-color: var(--pro-light);
        z-index: 9;
        // border-bottom: 0; //removed the border of nav
        .nav-link {
          white-space: pre;
          .pro-btn-link {
            --pro-button-hover-bg: var(--pro-primary-hover-bg);
            transition: none;
            // &:not(.active){
            //   // border-bottom: 2px solid var(--pro-outline-border);
            // }
            &.active {
              color: var(--pro-primary);
              border-top-left-radius: 6px;
              border-top-right-radius: 7px;
              border: none;
              box-shadow: 0 0 0 1px var(--pro-outline-border);
              &::after {
                background-color: var(--pro-primary);
              }
            }
            &:not(.active):active {
              background-color: var(--pro-button-hover-bg);
            }
          }
        }
      }
    }
  }
  .right_sidebar {
    width: 100%;
    max-width: 13rem;
    height: calc(100vh - 96px);
    position: sticky;
    top: 96px;
    z-index: 9;
    border-left: 1px solid var(--pro-outline-border);
    transition: 0.3s max-width ease-in-out;
  }
  :global(.close-right-drawer) {
    .right_sidebar {
      max-width: 75px;
    }
    .main_container {
      max-width: calc(100% - 28.7884rem);
    }
  }
  
  //fixng profile page pagination
  
  .main_wrapper {
    :global(.pro-pagination-wrapper) {
      z-index: 2;
      right: 207px;
      width: calc(100% - 592px);
    }
  }
  
  :global(.close-right-drawer) {
    .main_wrapper {
      :global(.pro-pagination-wrapper) {
        right: 76px;
        width: calc(100% - 461px);
      }
    }
  }
  
  .hide {
    display: none;
  }
  
  .pdf_container{
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
  }
  .assign_wrap{
    border-top: 1px solid var(--pro-outline-border);
    p{
      margin-bottom: 0;
      & ~ p{
        margin-top: 0.6rem;
      }
    }
  }



  .profile_layout_action {
    ul {
      padding: 0;
      border-inline: 1px solid var(--pro-outline-border);
      li {
        display: flex;
        button{
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  .table_wrap{
    border-bottom: 1px dashed var(--pro-outline-border);
    padding-bottom: size(25);
    table{
      td{
        font-size: 13px;
        font-weight: 500;
        &:first-child:nth-last-child(2), 
        &:first-child:nth-last-child(2) ~ td{
          width: 50%;
        }
        &:first-child:nth-last-child(2) ~ td{
          text-align: right;
        }
      }
    }
}

.frame_card{
  max-width: 184px;
  width: 100%;
  .frame_footer{
    border-block: 1px solid var(--pro-outline-border);
    padding: 7.5px 0;
    margin-top: 12px;
  }
  .frame_card_img{
    max-width: 150px;
    img{
      object-fit: contain;
    }
  }
}
.frame_row{
  --pro-gutter-y: 50px;
}

.cluster_img_wrap{
  overflow-x: auto;
  &::-webkit-scrollbar{
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #841d6d4a;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--pro-primary);
  }
  .cluster_img_box{
    max-width: 5rem;
    flex: 0 0 auto;
    width: 100%;
    position: relative;
    padding: 7px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px dashed transparent ;
    &.active{
      border-color: var(--pro-primary);
    }
    &::after{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: var(--pro-light);
      opacity: var(--opacity, 0);
      z-index: 0;
      pointer-events: none;
      transition: 0.25s ease;
    }
    .download_btn{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      background-color: var(--pro-primary);
      border: 1px solid var(--pro-light);
      color: var(--pro-light);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.25s;
      >span{
        will-change: transform;
        transition: transform 0.25s;
      }
      &:hover >span{
        transform: scale(0.8);
      }
    }
    &:hover{
      --opacity : 0.7;
      .download_btn{
        opacity: 1;
        pointer-events: auto;
      }
    }
    .img_root{
      position: relative;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}

.imgix_wrap{
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  border-radius: 8px;
  img{
    max-width: 100%;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    position: absolute;
  }
}