.root {
  :global(.dropdown) {
    width: auto;
    button {
      background: none;
      border: none;
      &::after {
        display: none;
      }
    }
  }
  .key_icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    align-items: center;
  }
  .input_wrap {
    > span {
      pointer-events: none;
    }
  }
}
.icon_wrap {
  max-width: 22px;
  min-width: 22px;
  &.active {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      right: 2px;
      width: 8px;
      height: 8px;
      background-color: var(--pro-danger);
      border-radius: 50%;
    }
  }
}

.notification_item {
  .img_wrap {
    max-width: 53px;
    min-width: 53px;
    width: 100%;
    > div {
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .content {
    > p {
      white-space: initial;
      font-size: 14px;
    }
    span {
      font-size: 10px;
      opacity: 0.6;
    }
  }
}
.notification_wrap {
  border-bottom: 1px solid var(--pro-outline-border);

  + p {
    font-size: 14px;
    cursor: pointer;
  }
}
.dropdown {
  --pro-dropdown-min-width: 352px;
  --pro-dropdown-link-hover-bg: transparent;
  --pro-dropdown-link-active-bg: transparent;
  --pro-dropdown-padding-x: 1rem;
  --pro-dropdown-item-padding-x: 0;
  --pro-dropdown-item-padding-y: 14px;
}
.profile_icon {
  color: var(--pro-dark);
}
